<app-dashboard-header></app-dashboard-header>
<div class="container page">
	<div class="row mt-4" >
	<div class="col-12" style="text-align: right;">
			<button type="button" class="btn btn-primary" (click)="addBlogPost()">{{"Додади нова содржина"|translate}}</button>
		</div>
		<div class="col-12 title" style="padding-top: 0">{{"Листа на блог содржина" | translate}}</div>
		
	</div>
	<div class="row list_grid list_grid_title mt-4">
		<div class="col-10" >{{"Листа на блог содржина" | translate}}</div>
		<div class="col-1"><fa-icon [icon]="faEdit"></fa-icon></div>
		<div class="col-1"><fa-icon [icon]="faTrash"></fa-icon></div>

	</div>
	<div class="row list_grid"
	*ngFor="let blog of blogP; let i = index" [class.active]="i == currentIndex">
	<div class="col-10 ">{{ blog.title }}</div>
	<div class="col-1"><a  routerLink="{{blog.id}}">{{"промени"| translate}}</a></div>
	<div class="col-1"><a (click)="deleteBlogPost(blog.id,blog.title)">{{"избриши"| translate}}</a></div>
</div>
<button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevBlogPage()">Previous</button>
<button class="btn btn-primary btn-sm float-right" (click)="nextBlogPage()" [disabled]="disableNextButton">Next</button>
</div>
<app-frontend-footer></app-frontend-footer>