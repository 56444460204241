import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { PublicationService } from 'src/app/shared/services/publication.service';
import { map } from 'rxjs/operators';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-frontend-publications',
  templateUrl: './frontend-publications.component.html',
  styleUrls: ['./frontend-publications.component.scss']
})
export class FrontendPublicationsComponent implements OnInit {

  publicationP:any;
faAngleDoubleDown=faAngleDoubleDown;	
	constructor(private pubPosts:PublicationService, public router:Router) { }

	ngOnInit(): void {
		this.getAllPublicationPost();
	}


	getAllPublicationPost(){
		let pubListener=this.pubPosts.getAll().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				
				this.publicationP = data;
				pubListener.unsubscribe();
			});
		}



}
