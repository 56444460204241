import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { AgmCoreModule } from '@agm/core';


// Reactive Form
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// App routing modules
import { AppRoutingModule } from './shared/routing/app-routing.module';

// App components
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from '../environments/environment';


// Auth service
import { AuthService } from "./shared/services/auth.service";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { DashboardAddReportComponent } from './components/dashboard-add-report/dashboard-add-report.component';

//datepicker
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MapComponent } from './components/map/map.component';
//import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { DashboardUpdateReportComponent } from './components/dashboard-update-report/dashboard-update-report.component';
import { FrontendAddReportComponent } from './components/frontend-add-report/frontend-add-report.component';
import { FrontendHeaderComponent } from './components/frontend-header/frontend-header.component';
import { DashboardBlogComponent } from './components/dashboard-blog/dashboard-blog.component';

import {BlogService} from './shared/services/blog.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DashboardBlogUpdateComponent } from './components/dashboard-blog-update/dashboard-blog-update.component';
import { DashboardBlogListComponent } from './components/dashboard-blog-list/dashboard-blog-list.component';
import { DatePipe } from '@angular/common';
import { FrontendPageComponent } from './components/frontend-page/frontend-page.component';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { DashboardDictionaryComponent } from './components/dashboard-dictionary/dashboard-dictionary.component';
import { DashboardContactComponent } from './components/dashboard-contact/dashboard-contact.component';
import {StaticPagesService} from './shared/services/static-pages.service';
import { FrontendHomeComponent } from './components/frontend-home/frontend-home.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { HighchartsComponent } from './components/highcharts/highcharts.component';
import { DashboardPublicationsComponent } from './components/dashboard-publications/dashboard-publications.component';
import { DashboardPublicationListComponent } from './components/dashboard-publication-list/dashboard-publication-list.component';
import { DashboardPublicationUpdateComponent } from './components/dashboard-publication-update/dashboard-publication-update.component';
import { FrontendPublicationsComponent } from './components/frontend-publications/frontend-publications.component';
import { FrontendFooterComponent } from './components/frontend-footer/frontend-footer.component';
import { FrontendStatisticsComponent } from './components/frontend-statistics/frontend-statistics.component';
import { FrontendBlogListComponent } from './components/frontend-blog-list/frontend-blog-list.component';
import { FrontendBlogPageComponent } from './components/frontend-blog-page/frontend-blog-page.component';
import { FrontendPublicationPageComponent } from './components/frontend-publication-page/frontend-publication-page.component';
import { FrontendStatisticsPostComponent } from './components/frontend-statistics-post/frontend-statistics-post.component';
import { DashboardReportsPublishedComponent } from './components/dashboard-reports-published/dashboard-reports-published.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DashboardReportsPublishedUpdateComponent } from './components/dashboard-reports-published-update/dashboard-reports-published-update.component';
import { FrontendAddReportSuccessComponent } from './components/frontend-add-report-success/frontend-add-report-success.component';
import {EmailService} from './shared/services/email.service';

//recaptcha
import { NgxCaptchaModule } from 'ngx-captcha';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    DashboardHeaderComponent,
    DashboardAddReportComponent,
    FrontendAddReportSuccessComponent,
    MapComponent,
    DashboardUpdateReportComponent,
    FrontendAddReportComponent,
    FrontendHeaderComponent,
    DashboardBlogComponent,
    DashboardBlogUpdateComponent,
    DashboardBlogListComponent,
    FrontendPageComponent,
    DashboardPageComponent,
    DashboardDictionaryComponent,
    DashboardContactComponent,
    FrontendHomeComponent,
    HighchartsComponent,
    DashboardPublicationsComponent,
    DashboardPublicationListComponent,
    DashboardPublicationUpdateComponent,
    FrontendPublicationsComponent,
    FrontendFooterComponent,
    FrontendStatisticsComponent,
    FrontendBlogListComponent,
    FrontendBlogPageComponent,
    FrontendPublicationPageComponent,
    FrontendStatisticsPostComponent,
    DashboardReportsPublishedComponent,
    NotFoundComponent,
    DashboardReportsPublishedUpdateComponent,
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    FontAwesomeModule,

    HighchartsChartModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    AngularEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })

  ],
  providers: [AuthService, BlogService,DatePipe],
  bootstrap: [AppComponent]
})

export class AppModule { }
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}