import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { ReportService } from 'src/app/shared/services/report.service';
declare const L: any; 
// import 'leaflet.markercluster';
//import 'leaflet/dist/images/marker-shadow.png';
//import 'leaflet/dist/images/react_pin.png';
import Geocoder from 'leaflet-control-geocoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-frontend-home',
	templateUrl: './frontend-home.component.html',
	styleUrls: ['./frontend-home.component.scss']
})
export class FrontendHomeComponent implements OnInit {
	id;
	changeClass;
	allMarkers;
	map;
	test;
	markers;
	markerIcon = L.icon({
  
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  // specify the path here
  iconUrl: "../assets/img/mypin.png",
  shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
});
	constructor(private rout:ActivatedRoute, private reportservice:ReportService) { }

	async ngOnInit() { 
		this.allMarkers = await this.reportservice.getMarkers();
		this.refresh();		
	}

	private initMap(): void {
		this.map = new L.Map('mapHome');
		// create the tile layer with correct attribution
		var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
		var osm = new L.TileLayer(osmUrl, {minZoom:8, maxZoom: 15, attribution: osmAttrib});  
		// set default coordinates
		this.map.setView(new L.latLng(41.662429, 21.759924),9);
		//add layer contributors
		this.map.addLayer(osm);
		//add markers on start
		this.createMarkers();
	}
	refresh(){
		this.initMap()
	}

	createMarkers(){
		this.markers = L.markerClusterGroup();
		for (var i = 0; i < this.allMarkers.length; i++) {
			var a = this.allMarkers[i]['map'];
			var id=this.allMarkers[i]['id'];
			var title = a.label;
			var marker = L.marker(new L.LatLng(a.lat, a.lng), { title: title, icon:this.markerIcon });
			marker.bindPopup('<a href="statistics/'+id+'">'+title+'</a>');
			this.markers.addLayer(marker);
		}
		this.map.addLayer(this.markers);
	}


	async filterMarkers(cat){
		this.allMarkers=await this.reportservice.filterReports(cat);
		//clear markers
		this.markers.clearLayers();
		//create markers based on category id
		this.createMarkers();
	}

	async getAllCat(){
		
		this.allMarkers=await this.reportservice.getMarkers();
		//clear markers
		this.markers.clearLayers();
		//create markers
		this.createMarkers();
	}

	//show filter map buttons 

	showBoxOnHover(element,id){
		if(element.target.id==id){
		this.changeClass=id+'_'+'show';
		}
	}

	showBoxIn(id){
		this.changeClass=id+'_'+'show';
	}

	out(){
		this.changeClass='';
	}

}
