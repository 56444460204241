import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { BlogService } from 'src/app/shared/services/blog.service';
import { map } from 'rxjs/operators';
import { faList, faMapMarkedAlt, faFilter, faCalendarAlt, faMapMarkerAlt,faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-frontend-blog-list',
	templateUrl: './frontend-blog-list.component.html',
	styleUrls: ['./frontend-blog-list.component.scss']
})
export class FrontendBlogListComponent implements OnInit {

	constructor(private blogPosts:BlogService, public router:Router) { }
	blogP;
	desc;
	lastBlog;
	mainFirstBlog;
	firstBlog;
	faCalendarAlt=faCalendarAlt;
	disableNextButton;
	disablePrevButton=true;
	ngOnInit(): void {
		this.getAllBlogPost();
	}


	getAllBlogPost(){
		let blogListener=this.blogPosts.getAll().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				this.blogP = data;
				if(data.length>10){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;
				}
				blogListener.unsubscribe();
				
			});

		}


		nextBlogPage(){
			
			let listener=this.blogPosts.nextBlog(this.lastBlog).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayBlog = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayBlog.push(data[i]);	
					}
					this.blogP = newArrayBlog;
					this.firstBlog=newArrayBlog[0];
					this.lastBlog=newArrayBlog[newArrayBlog.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevBlogPage(){
				let listener=this.blogPosts.prevBlog(this.firstBlog).snapshotChanges().pipe(
					map(actions => actions.map(a => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.blogP = data;
						this.firstBlog=data[0];
						this.lastBlog=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstBlog.title===this.firstBlog.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}








			}
