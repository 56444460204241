<app-dashboard-header></app-dashboard-header>
<form  [formGroup]="fblogUpdate" (ngSubmit)="onSubmit(fblogUpdate)">
<div class="container page">
	<div class="row">
		<div class="col-12 subtext"><a href="/dashboard">dashboard</a> / {{"Промени блог содржина"|translate}}</div>
		<div class="col-12 title">{{'Промени блог содржина'| translate}}</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="form-group">
				<label>{{"Наслов"|translate}}</label>
				<input type="text" formControlName="title" class="form-control">
			</div>
			<div class="group">
				<label for="blog_date">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
				<input type="date" class="form-control" formControlName="date" placeholder="YYYY-MM-dd">
			</div>
		</div>
		<div class="col-6">
			<div>{{"Прикачи фотографија"|translate}}</div>
			<img src="{{myImage}}" width="150">
			<input class="form-control" type="file" (change)="uploadImage($event, f.title)">
			<div class="progress">
				<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		</div>
		<div class="col-12">
			<div class="form-group">
				<label for="blog_desc">{{"Текст"|translate}}
				</label>
			<angular-editor   formControlName="text" [config]="config" ></angular-editor>
		</div>
	</div>
	<div class="col-12">
		<button class="btn btn-primary" type="submit">{{"Потврди"|translate}}</button>
	</div>
</div>
</div>
</form>
<app-frontend-footer></app-frontend-footer>