import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import Blog from '../models/blog';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
	providedIn: 'root'
})
export class BlogService {
	public dbPath='/blogPosts';
	blogPostRef:AngularFirestoreCollection<Blog>=null;
	constructor(private db:AngularFirestore, private myStorage:AngularFireStorage) {
		this.blogPostRef=db.collection(this.dbPath, ref => ref.orderBy('date', 'desc').limit(10));

	}

	create(blog: Blog): any {
		return this.blogPostRef.add({...blog}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});
	}

	update(id:string, data:any){
		return this.blogPostRef.doc(id).update(data);
	}

	getAll():AngularFirestoreCollection<Blog>{
		return this.blogPostRef;
	}

	nextBlog(next:any):AngularFirestoreCollection<Blog>{
		return this.db.collection(this.dbPath,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(11));
	}

	prevBlog(prev:any):AngularFirestoreCollection<Blog>{
		return this.db.collection(this.dbPath,ref => ref.orderBy('date', 'desc').endBefore(prev['date']).limitToLast(10));
	}	
	
	blogGetData(id:string){
		return this.blogPostRef.doc(id).valueChanges();
	}

	
	deleteData(id:string,name:string){
		const filePath = 'blogImages/'+name;
			const fileRef = this.myStorage.ref(filePath).delete();
		return this.blogPostRef.doc(id).delete();
	}
}
