<app-dashboard-header></app-dashboard-header>
<form [formGroup]="fblogAdd" (ngSubmit)="onSubmit(fblogAdd)">
<div class="container page">
	<div class="row">
		<div class="col-12 subtext"><a href="/dashboard">dashboard</a> / {{"Блог"|translate}}
	</div>
	<div class="col-12 title">{{'постави блог'| translate}}</div>
	<div class="col-12 req">{{"Полињата означени со * се задолжителни"|translate}}</div>
</div>
<div class="row">
	<div class="col-6">
		<div class="form-group">
			<label>{{"Наслов"|translate}}</label>
			<span class="mandatory">*</span>
			<input type="text" id="title" formControlName="title" class="form-control"
			[ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
			<div *ngIf="submitted && f.title.errors" class="invalid-feedback">
				<div *ngIf="f.title.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
			</div>
		</div>
	</div>
	<div class="group">
		<label for="blog_date">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
		<input type="date" formControlName="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
		<div *ngIf="submitted && f.date.errors" class="invalid-feedback">
			<div *ngIf="f.date.errors.required">Date is required</div>
		</div>
	</div>
</div>
<div class="col-6">
	<div>{{"Прикачи фотографија"|translate}}</div>
	<input class="form-control" type="file"  (change)="uploadImage($event, f.title)" accept=".png,.jpg" >
	<div class="progress">
		<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
	</div>
</div>
<div class="col-12 form-group">
	<label for="blog_desc">{{"Текст"|translate}}
	</label>
	<span class="mandatory">*</span>
	<angular-editor  name="text"
	formControlName="text"  [ngClass]="{ 'is-invalid': submitted && f.text.errors }" required [config]="config">
	</angular-editor>
	<div *ngIf="submitted && f.text.errors" class="invalid-feedback">
		<div *ngIf="f.text.errors.required"><span>{{'Ова поле е задолжително'|translate}}</span></div>
	</div>
</div>
<div class="col-12">
	<button class="btn btn-primary" type="submit">{{"Потврди"|translate}}</button>
</div>
</div>
</div>
</form>
<app-frontend-footer></app-frontend-footer>