import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-dashboard-contact',
  templateUrl: './dashboard-contact.component.html',
  styleUrls: ['./dashboard-contact.component.scss']
})
export class DashboardContactComponent implements OnInit {
fContact:FormGroup;
submitted=false;
  constructor(private fb:FormBuilder) { }

  ngOnInit(): void {
  }
  config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "15rem",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: true,
		customClasses: [
		{
			name: "quote",
			class: "quote"
		},
		{
			name: "redText",
			class: "redText"
		},
		{
			name: "titleText",
			class: "titleText",
			tag: "h1"
		}
		],
	};


  get f(){
  	return this.fContact.controls;
  }

  onSubmit({value}){
  	this.submitted=true;

  }

}
