import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import Publication from '../models/publication';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {
public dbPath='/publications';
	publicationPostRef:AngularFirestoreCollection<Publication>=null;

	constructor(private db:AngularFirestore, private myStorage:AngularFireStorage) {
		this.publicationPostRef=db.collection(this.dbPath, ref => ref.orderBy('date','desc'));

	}

	create(publication: Publication): any {
		return this.publicationPostRef.add({...publication}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});
	}

	update(id:string, data:any){
		return this.publicationPostRef.doc(id).update(data);
	}

	getAll():AngularFirestoreCollection<Publication>{
		return this.publicationPostRef;
	}

	
	publicationGetData(id:string){
		return this.publicationPostRef.doc(id).valueChanges();
	}

	deleteData(id:string,name:string){
		const pdfFilePath = 'publicationPdf/'+name;
		const imgFilePath='publicationImage/'+name;
		this.myStorage.ref(pdfFilePath).delete();
		this.myStorage.ref(imgFilePath).delete();
		return this.publicationPostRef.doc(id).delete();
	}
}
