import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class UploadImageService{
	
	constructor(private myStorage: AngularFireStorage, private http:HttpClient) { }

	uploadImage(filePath,file){
		const task = this.myStorage.upload(filePath, file);
		return task;		
	}

	uploadPdf(filePath,file){
		const task = this.myStorage.upload(filePath, file);
		return task;		
	}


}
