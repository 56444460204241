import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frontend-add-report-success',
  templateUrl: './frontend-add-report-success.component.html',
  styleUrls: ['./frontend-add-report-success.component.scss']
})
export class FrontendAddReportSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
