import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { PublicationService } from 'src/app/shared/services/publication.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '@angular/common'
import { AngularFireStorage } from '@angular/fire/storage';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-dashboard-publication-update',
  templateUrl: './dashboard-publication-update.component.html',
  styleUrls: ['./dashboard-publication-update.component.scss']
})
export class DashboardPublicationUpdateComponent implements OnInit {
id:string;
	publication;
	submitted=false;
	uploadPercent;
	uploadPercentPdf;
	downloadURL;
	downloadURLPdf;
	myImage;
	myPdf;
	title;
	date;
	fpublicationUpdate: FormGroup;
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "10rem",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		{
			name: "quote",
			class: "quote"
		},
		{
			name: "redText",
			class: "redText"
		},
		{
			name: "titleText",
			class: "titleText",
			tag: "h1"
		}
		],
	};
	constructor(public route:Router,
		private rout:ActivatedRoute,
		public pubService:PublicationService,
		private fb: FormBuilder, private datepipe:DatePipe, 
		private myStorage:AngularFireStorage, 
		private uploadImageService:UploadImageService) { 

		this.createForm();}

		ngOnInit(): void {
			this.id = this.rout.snapshot.paramMap.get('id');
			let pubListener=this.publication=this.pubService.publicationGetData(this.id).subscribe(a=>{
				this.fpublicationUpdate.patchValue(a);
				console.log(a);
				this.myImage=a.image;
				this.myPdf=a.pdf;
				this.date=a.date;
				pubListener.unsubscribe();
			});
		}


		uploadImage(event, name) {
			const file = event.target.files[0];
			const filePath = 'publicationImages/'+name.value;
			const fileRef = this.myStorage.ref(filePath);
			const task=this.uploadImageService.uploadImage(filePath, file);
			this.uploadPercent = task.percentageChanges();
			// get notified when the download URL is available
			task.snapshotChanges().pipe(
				finalize(() => {
					fileRef.getDownloadURL().subscribe(downloadURL => {
						this.downloadURL=downloadURL;
						this.f.image.patchValue(this.downloadURL);
					});
				})
				).subscribe();
			

		}

		uploadPdf(event, name) {
		const file = event.target.files[0];
		const filePath = 'publicationPdf/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const taskPdf=this.uploadImageService.uploadPdf(filePath, file);
		this.uploadPercentPdf = taskPdf.percentageChanges();
		// get notified when the download URL is available
		taskPdf.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					this.downloadURLPdf=downloadURL;
					this.f.pdf.patchValue(this.downloadURLPdf);
				});
			})
			).subscribe();
		

	}

		get f() { return this.fpublicationUpdate.controls; }


		private createForm() {
			//inicialization
			this.fpublicationUpdate = this.fb.group({
				title: '',
				text: '',
				image:'',
				pdf:'',
				date:''
			});
		}


		onSubmit({ value}) {

			if(this.downloadURL || this.downloadURLPdf)
			{
				this.f.image.patchValue(this.downloadURL);
				this.f.pdf.patchValue(this.downloadURLPdf);
			}	
			this.pubService.update(this.id, value).then((value) => {
				console.log('Created new item successfully!');
				this.route.navigate(['dashboard-publication-list']);
				this.submitted = true;
			});

		}


}
