import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { BlogService } from 'src/app/shared/services/blog.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '@angular/common'
import { AngularFireStorage } from '@angular/fire/storage';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 
import { finalize } from 'rxjs/operators';

import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
@Component({
	selector: 'app-dashboard-blog-update',
	templateUrl: './dashboard-blog-update.component.html',
	styleUrls: ['./dashboard-blog-update.component.scss']
})
export class DashboardBlogUpdateComponent implements OnInit {
	id:string;
	blog;
	submitted=false;
	uploadPercent;
	downloadURL;
	myImage;
	date;
	title;
	fblogUpdate: FormGroup;
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "15rem",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		{
			name: "quote",
			class: "quote"
		},
		{
			name: "redText",
			class: "redText"
		},
		{
			name: "titleText",
			class: "titleText",
			tag: "h1"
		}
		],
	};
	constructor(public route:Router,
		private rout:ActivatedRoute,
		public blogService:BlogService,
		private fb: FormBuilder, private datepipe:DatePipe, 
		private myStorage:AngularFireStorage, 
		private uploadImageService:UploadImageService) { 

		this.createForm();}

		ngOnInit(): void {
			this.id = this.rout.snapshot.paramMap.get('id');
			this.blog=this.blogService.blogGetData(this.id).subscribe(a=>{
				this.fblogUpdate.patchValue(a);
				this.myImage=a.image;
				this.blog.unsubscribe();
			});
		}


		uploadImage(event, name) {
			const file = event.target.files[0];
			const filePath = 'blogImages/'+name.value;
			const fileRef = this.myStorage.ref(filePath);
			const task=this.uploadImageService.uploadImage(filePath, file);
			this.uploadPercent = task.percentageChanges();
			// get notified when the download URL is available
			task.snapshotChanges().pipe(
				finalize(() => {
					fileRef.getDownloadURL().subscribe(downloadURL => {
						this.downloadURL=downloadURL;
						this.f.image.patchValue(this.downloadURL);
					});
				})
				).subscribe();
			

		}

		get f() { return this.fblogUpdate.controls; }


		private createForm() {
			//inicialization
			this.fblogUpdate = this.fb.group({
				title: '',
				text: '',
				date:['', (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)],
				image:''
			});
		}


		onSubmit({ value}) {

			if(this.downloadURL)
			{
				this.f.image.patchValue(this.downloadURL);
			}	
			this.blogService.update(this.id, value).then((value) => {
				console.log('Created new item successfully!');
				this.route.navigate(['dashboard-blog-list']);
				this.submitted = true;
			});

		}


	}



