<app-frontend-header></app-frontend-header>
<div class="container page">
	<div class="row">
		<div class="col-12">
			<div class="title">{{"Приказ на пријави"|translate}}</div>
		</div>
		<div class="col-md-9 col-xs-12">
			<div class="report_filter">
				<div (click)="showListReport()" class="filterButton">
				<div class="filter_item"><fa-icon [icon]="faList"></fa-icon>  {{"Листа"|translate}}</div>
				</div>
				<div (click)="showMapReport()" class="filterButton">
				<div class="filter_item"><fa-icon [icon]="faMapMarkedAlt"></fa-icon>  {{"Мапа"|translate}}</div>
				</div>
			</div>
			<div  *ngIf="showList">
				<div class=" front_list_grid" 
				*ngFor="let rep of reports; let i = index" [class.active]="i == currentIndex" >
				<div class="row">
					<div class="col-3">
						<div *ngIf="rep.image; else defaultImage" class="report_image_box"><div style="background-image: url('{{ rep.image }}');" class="report_image_box"></div>
					</div>
					<ng-template #defaultImage class="report_image_box">
						<div style="background-image: url('/assets/img/no_image.png');" class="report_image_box"></div></ng-template>
					</div>
					<div class="col-md-9 col-xs-12"> 
					<div class="report_data">
						<div class="report_date"> <span style="color:#bb506c;padding:5px"><fa-icon [icon]="faClock"></fa-icon> </span>
						{{rep.data.reportDate | date }}</div>
						<a routerLink="{{rep.id}}">
						<div class="report_title">{{rep.data.title}}</div>
						<div>{{ (rep.data.description.length>100)? (rep.data.description | slice:0:100):(rep.data.description)  }} <span class="read_more">{{"...Прочитајте повеќе..."| translate }}</span>
						</div>
						</a>
						<br>
						<div class="report_location"><fa-icon [icon]="faMapMarkerAlt"></fa-icon> {{rep.data.place_location}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="visibility: {{showMap}}">
			<div class="map-container" style="z-index: 1">
				<div class="map-frame">
					<div id="mapList"></div>
				</div>
			</div>
		</div>
		<button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevReportPage()">{{"Претходна страница"|translate}}</button>
	<button class="btn btn-primary btn-sm float-right" (click)="nextReportPage()" [disabled]="disableNextButton">{{"Следна страница"|translate}}</button>
	</div>
	<div class="col-md-3 col-xs-12">
		<div class="report_filter" style="padding: 10px"><fa-icon [icon]="faFilter"></fa-icon> {{"Филтрирај по категорија"|translate}}</div>
		<div class="list-group-item list-group-hover {{colorAll}}" (click)="getAllCat()">
			{{"Сите категории"|translate}}</div>
			<div *ngFor="let report of categories; let i = index" [class.active]="i == currentIndex" >
				<div class="list-group-item list-group-hover" (click)="filterMarkers(report.name, i)" [ngClass]="{'hover': divStyle === i} ">{{report.name|translate}} ({{report.length}})</div>		
			</div>
		<!-- <ul style="padding: 0" *ngFor="let report of categories; let i = index" [class.active]="i == currentIndex" >
	-->
			<!-- <li class="list-group-item list-group-hover" (click)="getAllCat()">
				{{"Сите категории"|translate}} </li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Навредливи коментaри, свиркање')">{{"Навредливи коментaри, свиркање"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Непријатно допирање или покажување гениталии')">{{"Непријатно допирање или покажување гениталии"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Следење, зјапање или намерно попречување пат')">{{"Следење, зјапање или намерно попречување пат"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Закана')">{{"Закана"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Физички напад')">{{"Физички напад"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Сексуално насилство')">{{"Сексуално насилство"|translate}}</li> -->
				
				<!-- <li class="list-group-item list-group-hover" (click)="filterMarkers(report.name)">{{report.name}} ({{report.length}})</li>			</ul> -->
			</div>
		</div>
		
	</div>
	<app-frontend-footer></app-frontend-footer>

