<app-frontend-header></app-frontend-header>
<div class="container page">
	<div class="row">
		<div class="col-12">
			<div class="title" *ngIf="currentL=='mk'">{{pageData?.title}}</div>
			<div class="title" *ngIf="currentL!='mk'">{{pageData?.title_en}}</div>

			 <span [innerHTML]= "pageData?.text" *ngIf="currentL=='mk'"></span>
			 <span [innerHTML]= "pageData?.text_en" *ngIf="currentL!='mk'"></span>

		</div>
	</div>
</div>
<app-frontend-footer></app-frontend-footer>

