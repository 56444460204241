import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { faUserAstronaut, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  constructor(public authService: AuthService,public router: Router,public ngZone: NgZone, public translate:TranslateService)
  {
    translate.addLangs(['mk', 'en']);
    translate.setDefaultLang('mk');
  }
  ngOnInit(): void {
  }
  faUserAstronaut = faUserAstronaut;
  faSignOutAlt=faSignOutAlt;

  switchLang(lang: string) {
    this.translate.use(lang);

  }

  signOut(){
    this.authService.SignOut();
  }

}