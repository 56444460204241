<app-dashboard-header></app-dashboard-header>
<div class="container page">
	<div class="row mt-4" >
		<div class="col-9 title">	{{"Листа на пријави" | translate}}
		</div>
		<div class="col-3" style="text-align: right;">
			<button type="button" class="btn btn-primary" (click)="addReport()">{{"Додади нова пријава"|translate}}</button>
		</div>
	</div>
	<div style="position:relative;margin-top: 50px">
		<ul class="nav nav-tabs" style="left: 0">
			<li class="nav-item">
			<a class="nav-link" href="/dashboard">{{'Одобри пријава'|translate}}</a>
			</li>
			<li class="nav-item">
			<a class="nav-link" aria-current="page" href="/dashboard-published-reports" class="{{url}}">{{'Одобрени пријави'|translate}}</a>
			</li>


			

		</ul>
	</div>

	<div class="list_grid list_grid_title mt-4">

		<div class="row">

			<div class="col-7">{{"Одобрена листа на пријави" | translate}}</div>
			<div class="col-3"><fa-icon [icon]="faCheck"></fa-icon></div>
			<div class="col-1"><fa-icon [icon]="faEdit"></fa-icon></div>
			<div class="col-1"><fa-icon [icon]="faTrash"></fa-icon></div>
		</div>
	</div>
	<div class=" list_grid"
	*ngFor="let rep of reportPublished; let i = index" [class.active]="i == currentIndex">
	<div class="row">

		<div class="col-7"><a routerLink="{{rep.id}}">{{ rep.title }}</a></div>
		<div class="col-3"> {{rep.published}}</div>
		<div class="col-1"><a  routerLink="{{rep.id}}">{{"промени"| translate}}</a></div>
		<div class="col-1"><a (click)="deleteReport(rep.id,rep.title)">{{"избриши"| translate}}</a></div>
	</div>

</div>
<button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevReportPage()">Previous</button>

<button class="btn btn-primary btn-sm float-right" (click)="nextReportPage()" [disabled]="disableNextButton">Next</button>
</div>
<app-frontend-footer></app-frontend-footer>