<app-frontend-header></app-frontend-header>
<div class="container page">
	<div class="row">

		<div class="col-12">
			<div class="title">{{"Студии"|translate}}</div>
		</div>
		<div class="col-md-6 col-xs-12 b" *ngFor="let pub of publicationP">
			<div class="row pub " style="height: 100%">
				<div class="col-md-4 col-xs-12">
					<div class="publication_image" style="background-image:url('{{pub.image}}')"></div>
					<a href="{{pub.pdf}}" target="_blank" >
						<div class="box">
							<div class="download"><fa-icon [icon]="faAngleDoubleDown"></fa-icon> </div>
							<div class="date">{{pub.date}}</div>
						</div>
					</a>
				</div>

				<div class="col-md-8 col-xs-12">
					<a href="{{pub.pdf}}" target="_blank">
						<div class="publication_title">{{pub.title}}</div>
						<div [innerHTML]= "pub.text" ></div>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<app-frontend-footer></app-frontend-footer>
