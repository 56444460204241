import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute} from "@angular/router";

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-frontend-header',
  templateUrl: './frontend-header.component.html',
  styleUrls: ['./frontend-header.component.scss']
})
export class FrontendHeaderComponent implements OnInit {
  public isCollapsed = true;
  public page;

  constructor(public authService: AuthService,public router: Router,public ngZone: NgZone, public translate:TranslateService,    private rout:ActivatedRoute,
)
  {
    translate.setDefaultLang(localStorage.getItem('language'));
    translate.use(localStorage.getItem('language'));
  }

  ngOnInit(): void {
    this.translate.addLangs(['mk', 'en']);
    this.translate.setDefaultLang('mk');
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("language",lang);
      this.page = this.rout.snapshot.paramMap.get('page');
      if(this.page){
        window.location.reload();
      }

  }

  


}
