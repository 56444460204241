import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import staticPage from '../models/staticPage';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class StaticPagesService {
public dbPath='/staticPages';
	staticPageRef:AngularFirestoreCollection<staticPage>=null;
	constructor(private db:AngularFirestore, private myStorage:AngularFireStorage) {
		this.staticPageRef=db.collection(this.dbPath,ref => ref.orderBy('date', 'desc'));

	}

	// create(staticpage: staticPage): any {
	// 	return this.staticPageRef.add({...staticpage}).then(() => {
	// 		console.log("Document successfully written!");
	// 	})
	// 	.catch((error) => {
	// 		console.error("Error writing document: ", error);
	// 	});
	// }

	update(id:string, data:any){
		return this.staticPageRef.doc(id).update(data);
	}

	// getAll():AngularFirestoreCollection<staticPage>{
	// 	return this.staticPageRef;
	// }
	
	staticPageGetData(id:string){
		return this.staticPageRef.doc(id).valueChanges();
	}

	// deleteData(id:string,name:string){
	// 	const filePath = 'blogImages/'+name;
	// 		const fileRef = this.myStorage.ref(filePath).delete();
	// 	return this.staticPageRef.doc(id).delete();
	// }
}
