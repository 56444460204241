import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/shared/services/report.service';
import {Router, ActivatedRoute} from "@angular/router";
import { faList, faMapMarkedAlt, faFilter, faCalendarAlt, faMapMarkerAlt,faClock } from '@fortawesome/free-solid-svg-icons';
declare const L: any; 
import Geocoder from 'leaflet-control-geocoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Component({
	selector: 'app-frontend-statistics-post',
	templateUrl: './frontend-statistics-post.component.html',
	styleUrls: ['./frontend-statistics-post.component.scss']
})
export class FrontendStatisticsPostComponent implements OnInit {

	id;
	reportData;
	policeAnswer;
	faCalendarAlt=faCalendarAlt;
	faMapMarkerAlt=faMapMarkerAlt;
	faClock=faClock;
	markers;
	map;

	lastFiveReports;
	markerIcon = L.icon({
		iconAnchor: [10, 41],
		popupAnchor: [2, -40],
		// specify the path here
		iconUrl: "../assets/img/mypin.png",
		shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
	});
	constructor(private reportservice:ReportService, private rout:ActivatedRoute, private router:Router) { }
	

	async ngOnInit() {
		this.id = this.rout.snapshot.paramMap.get('id');
		let reportListener=this.reportservice.publishedReportGetData(this.id).valueChanges().subscribe(a=>{
			this.reportData=a;
			
			reportListener.unsubscribe();
			this.refresh();
			for (var i=0;i<a.policeAnswer.length;i++){
				if(a.policeAnswer[i]['ischecked']){
					this.policeAnswer=true;
				}
			}
		});
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.getLastFiveReports();

	}

	private initMap(): void {
		this.map = new L.Map('mapStatisticPost');
		// create the tile layer with correct attribution
		var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
		var osm = new L.TileLayer(osmUrl, {minZoom:8, maxZoom: 20, attribution: osmAttrib});  
		// set default coordinates
		this.map.setView(new L.latLng(this.reportData.mapData.lat, this.reportData.mapData.lng),16);
		//add layer contributors
		this.map.addLayer(osm);
		//add markers on start
		this.createMarkers();
	}


	refresh(){
		this.initMap()
	}


	createMarkers(){
		this.markers = L.markerClusterGroup();
		var marker = L.marker(new L.LatLng(this.reportData.mapData.lat, this.reportData.mapData.lng), { title: this.reportData.title, icon:this.markerIcon });
		marker.bindPopup(this.reportData.title);
		this.markers.addLayer(marker);
		this.map.addLayer(this.markers);
	}

	getLastFiveReports(){
		let lastFiveListener=this.reportservice.reportGetLastFive().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				this.lastFiveReports=data;
				lastFiveListener.unsubscribe();
			});
	}


	

	

}
