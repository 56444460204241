<app-dashboard-header></app-dashboard-header>
<form [formGroup]="fContact" (onSubmit)="onSubmit(fContact)">
	<div class="container page">
		<div class="row">
			<div class="col-12 subtext"><a href="/dashboard">dashboard</a> / {{"За проектот"|translate}}
			</div>
			<div class="col-12 title">{{'Додавање на нова страница'| translate}}</div>
			<div class="col-12 req">{{"Полињата означени со * се задолжителни"|translate}}</div>

			<div class="col-12 form-group">
				<label>{{"Наслов"|translate}}</label>
				<span class="mandatory">*</span>
				<input type="text" id="title" formControlName="title" class="form-control"
				[ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
				<div *ngIf="submitted && f.title.errors" class="invalid-feedback">
					<div *ngIf="f.title.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="col-12 form-group">
				<label for="blog_desc">{{"Текст"|translate}}
				</label>
				<span class="mandatory">*</span>
				<angular-editor  name="text"
				formControlName="text" [ngClass]="{ 'is-invalid': submitted && f.text.errors }" required [config]="config">
			</angular-editor>
			<div *ngIf="submitted && f.text.errors" class="invalid-feedback">
				<div *ngIf="f.text.errors.required"><span>{{'Ова поле е задолжително'|translate}}</span></div>
			</div>
		</div>
		<div class="col-12">
			<button class="btn btn-primary" type="submit">{{"Потврди"|translate}}</button>
		</div>
	</div>

</div>
</form>
<app-frontend-footer></app-frontend-footer>
