<app-frontend-header></app-frontend-header>
<div class="container page">
	<div class="row">
		<div class="col-12 subtext"><a href="/">{{"Почетна"|translate}}</a> / <a href="/blog">{{"Блог содржина"|translate}}</a></div>

		<div class="col-12">
			<div class="title">{{blogData?.title}}</div>
			<div *ngIf="blogData?.image" class="blog_image blog_post_image" style="background-image: url('{{blogData?.image}}');"></div>
			<div [innerHTML]= "blogData?.text" style="padding: 20px 0px"></div>
			<div style="padding: 20px 0px">{{'Објавено на:'|translate}} {{blogData?.date | date:'dd-MM-yyyy'}}</div>

		</div>
	</div>
</div>
<app-frontend-footer></app-frontend-footer>

