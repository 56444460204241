<app-frontend-header></app-frontend-header>
<div class="container" style="height: 50vh">
	<div class="row" >
		<div class="col-md-12 col-xs-12 e404" >{{"404 грешка"|translate}}</div>
				<div class="col-md-12 col-xs-12">{{"Страницата што ја барате не постои."|translate}}</div>

		<div class="col-md-12 col-xs-12"><a href="/">{{"Ве молиме вратете се на почетната страница."|translate}}</a></div>
	</div>
</div>

<app-frontend-footer></app-frontend-footer>