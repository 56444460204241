<div class="full_width signin_bkg">
	<div class="center_v">
		<div class="signin_form">
			<div class="form-group">
				<label for="emailInput">Email address</label>
				<input type="email" class="form-control" id="emailInput" aria-describedby="emailHelp" placeholder="Enter email" #useremail>
				<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
			</div>
			<div class="form-group">
				<label for="emailPass">Password</label>
				<input type="password" class="form-control" id="emailPass" placeholder="Password" #userpassword >
				<small id="emailHelp1" class="form-text text-muted">2 clicks for enter</small>
			</div>
			<button type="submit" class="btn btn-primary" (click)="authService.SignIn(useremail.value, userpassword.value)">Submit</button>
		</div>
	</div>
</div>


