<app-dashboard-header></app-dashboard-header>
<form  [formGroup]="fpublicationUpdate" (ngSubmit)="onSubmit(fpublicationUpdate)">
<div class="container page">
	<div class="row">
		<div class="col-12 subtext"><a href="/dashboard">dashboard</a> / {{"Промени студија"|translate}}</div>
		<div class="col-12 title">{{'Промени студија'| translate}}</div>
	</div>
	<div class="row">
		<div class="col-8">
			<div class="form-group">
				<label>{{"Наслов"|translate}}</label>
				<input type="text" formControlName="title" class="form-control">
			</div>
		</div>
		<div class="col-4">
			<div class="form-group">
				<label >{{"Година на издавање"|translate}}</label>
					<input type="text" formControlName="date" class="form-control"/>
			</div>
		</div>
		<div class="col-6">
			<div>{{"Промени фотографија"|translate}}</div>
			<img src="{{myImage}}" height="100">
			<input class="form-control" type="file" (change)="uploadImage($event, f.title)">
			<div class="progress">
				<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		</div>
		<div class="col-6">
			<div>{{"Промени пдф"|translate}}</div>
			<span style="height: 100px">{{myPdf}}</span>
			<input class="form-control" type="file" (change)="uploadPdf($event, f.title)">
			<div class="progress">
				<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercentPdf | async) + '%'" [attr.aria-valuenow]="(uploadPercentPdf | async)" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		</div>
		<div class="col-12">
			<div class="form-group">
				<label for="blog_desc">{{"Краток опис на студијата"|translate}}
				</label>
			<angular-editor   formControlName="text" [config]="config" ></angular-editor>
		</div>
	</div>
	<div class="col-12">
		<button class="btn btn-primary" type="submit">{{"Потврди"|translate}}</button>
	</div>
</div>
</div>
</form>
<app-frontend-footer></app-frontend-footer>