<app-frontend-header></app-frontend-header>
<div class="container page">
	<div class="row">
		<div class="col-12 title">{{"Ви благодариме на поднесената пријава"| translate}}</div>
		<div class="col-12">{{"Вашата пријава ќе биде разгледана и ставена во датабазата на пријави" | translate}}</div>
		<div class="col-12"><a href="/">{{"Ве молиме вратете се на почетната страна" | translate}}</a></div>
	</div>
</div>
<app-frontend-footer></app-frontend-footer>

