import { Component, OnInit } from '@angular/core';
import Report from 'src/app/shared/models/report';
import Publication from 'src/app/shared/models/publication';
import { AngularFireStorage } from '@angular/fire/storage';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 
import { finalize } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { PublicationService } from 'src/app/shared/services/publication.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

@Component({
	selector: 'app-dashboard-publications',
	templateUrl: './dashboard-publications.component.html',
	styleUrls: ['./dashboard-publications.component.scss']
})
export class DashboardPublicationsComponent implements OnInit {
	publication:Publication=new Publication();
	submitted=false;
	uploadPercent;
	uploadPercentPdf;
	downloadURLPdf;
	downloadURL;
	fpublicationAdd: FormGroup;

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "10rem",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: true,
		customClasses: [
		{
			name: "quote",
			class: "quote"
		},
		{
			name: "redText",
			class: "redText"
		},
		{
			name: "titleText",
			class: "titleText",
			tag: "h1"
		}
		],
	};

	constructor(private myStorage: AngularFireStorage,
		private uploadImageService:UploadImageService,//as uload link
		private publicationService:PublicationService,
		public router:Router,
		private fb: FormBuilder) { }

	ngOnInit(): void {
		this.createForm();
	}


	private createForm() {
		//inicialization
		this.fpublicationAdd = this.fb.group({
			title: ['', Validators.required],
			text: ['', Validators.required],
			pdf:['', Validators.required],
			image:['', Validators.required],
			date: ['', [Validators.required]]

		});
	}
	// convenience getter for easy access to form fields
	get f() { return this.fpublicationAdd.controls; }


	uploadImage(event, name) {
		const file = event.target.files[0];
		const filePath = 'publicationImages/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const task=this.uploadImageService.uploadImage(filePath, file);
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available
		task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					this.downloadURL=downloadURL;
					this.f.image.patchValue(this.downloadURL);
				});
			})
			).subscribe();
		

	}


	uploadPdf(event, name) {
		const file = event.target.files[0];
		const filePath = 'publicationPdf/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const taskPdf=this.uploadImageService.uploadPdf(filePath, file);
		this.uploadPercentPdf = taskPdf.percentageChanges();
		// get notified when the download URL is available
		taskPdf.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					this.downloadURLPdf=downloadURL;
					this.f.pdf.patchValue(this.downloadURLPdf);
				});
			})
			).subscribe();
		

	}

	onSubmit({value}) {
		this.submitted = true;
		if (this.fpublicationAdd.invalid) {
			return;
		}
		if(this.downloadURL || this.downloadURLPdf)
		{
			this.f.pdf.patchValue(this.downloadURLPdf);
			this.f.image.patchValue(this.downloadURL);
		}
		this.publicationService.create(value).then((value) => {
			this.router.navigate(['dashboard-publication-list']);
		});
	}


}
