import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/shared/services/blog.service';
import {Router, ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-frontend-blog-page',
  templateUrl: './frontend-blog-page.component.html',
  styleUrls: ['./frontend-blog-page.component.scss']
})
export class FrontendBlogPageComponent implements OnInit {
id;
blogData;
  constructor( private blogservice:BlogService, private rout:ActivatedRoute) {		
 }
	ngOnInit(): void {
		this.id = this.rout.snapshot.paramMap.get('post');
		let blogData=this.blogservice.blogGetData(this.id).subscribe(a=>{
			this.blogData=a;
			blogData.unsubscribe();
		});
	}
}
