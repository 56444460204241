<!-- <div>
  <agm-map [latitude]='lat'
    [longitude]='long'
    [zoom]='zoom' [mapTypeControl]="true"   (mapClick)="mapClicked($event)">
    <agm-marker-cluster imagePath="assets/img/m">
      <agm-marker
        *ngFor="let marker of markers"
        [latitude]="marker.lat"
        [longitude]="marker.lng" [markerDraggable]="marker.draggable">
        <agm-info-window #infoWindow>
          {{ marker.label }}
        </agm-info-window>
      </agm-marker>
    </agm-marker-cluster>
  </agm-map>
  <div class="text" style="margin-top: 20px">{{"Прецизирај го местото на локацијата"|translate}}<span class="mandatory">*</span></div>
  <div class="subtext">{{"(пр:Рузвелтова Скопје, Рузвелтова 46 Скопје...)"|translate}}</div>
  <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
</div> -->

<div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
