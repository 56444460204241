import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router } from "@angular/router";
import { ReportService } from 'src/app/shared/services/report.service';
import Report from 'src/app/shared/models/report';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 

import { MapComponent } from 'src/app/components/map/map.component';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import {EmailService} from 'src/app/shared/services/email.service';

@Component({
	selector: 'app-frontend-add-report',
	templateUrl: './frontend-add-report.component.html',
	styleUrls: ['./frontend-add-report.component.scss']
})
export class FrontendAddReportComponent implements OnInit {
	report:Report=new Report();
	submitted = false;
	freportAdd:FormGroup;
	mapDataValid:boolean=false;
	reportImage:any;
	uploadPercent;
	downloadURL;
	checkedCat=false;
	checkedAnswer=false;
	siteKey:string="6LctPWgdAAAAAAuGLK_DPmqZoFE3wHaWFMzf0zLu";
	recaptcha:any;
	constructor(private reportService:ReportService,
		public router:Router,
		private myStorage: AngularFireStorage,
		private uploadImageService:UploadImageService,
		private fb:FormBuilder, private emailService:EmailService
		) {

	}
	ngOnInit(): void {
		this.createForm();
	}

	categories:any=[
	{ name:'Навредливи коментaри, свиркање', ischecked:false},
	{ name:'Непријатно допирање или покажување гениталии',ischecked:false},
	{ name:'Следење, зјапање или намерно попречување пат',ischecked:false},
	{name:"Закана",ischecked:false},
	{ name:"Физички напад",ischecked:false},
	{ name:"Сексуално насилство",ischecked:false},
	{ name:"Потврдени пријави",ischecked:false}];
	are_you:any=[{id:1, name:"Сведок"},{id:2,name:"Жртва"}];
	it_happened:any=[{id:1, name:"Повеќе од 5 пати"},{id:2, name:"2 до 5 пати"},{id:3,name:"Еднаш"}];
	place_type:any=[{id:1,name:"Улица или паркинг"},{id:2, name:'Пазар'},{id:3,name:"Јавен превоз или додека сте чекале"},{id:4,name:'Продавница'},{id:5,name:'Парк'},{id:6,name:'Кола'},{id:7,name:'Работа'},{id:8,name:'Диско'},{id:9,name:'Образовна установа'},{id:10,name:'Друга јавна зграда'},{id:11,name:'Не се сеќавам'},{id:12,name:'Без одговор'}];
	report_police:any=[{id:1,name:'Да'},{id:2,name:'Не'},{id:3,name:'Без одговор'}];
	police_attitude:any=[{id:1, name:"Многу задоволни"},{id:2,name:"Задоволни"},{id:3,name:"Незадоволни"},{id:4,name:"Многу незадоволни"},{id:5,name:"Не знам"},{id:6,name:'Без одговор'}];
	police_answer:any=[{name:"Ме обвинија мене за инцидентот", ischecked:false},
	{name:"Не направија ништо", ischecked:false},{name:"Го истражија инцидентот",ischecked:false},{name:"Го минимизираа/тривијализираа инцидентот",ischecked:false},{name:"Го запишаа инцидентот", ischecked:false},{name:"Го фатија сторителот", ischecked:false},{ischecked:false,name:'Без одговор'}]

	createForm(){
		this.freportAdd=this.fb.group({
			title:['',Validators.required],
			description:['',Validators.required],
			reportDate:['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
			time:'',
			reportCat:this.fb.array( this.categories,[Validators.required] ),
			youAre:['',Validators.required],
			happened:['',Validators.required],
			placeType:['',Validators.required],
			reportPolice:['',Validators.required],
			policeAttitude:['',Validators.required],
			policeAnswer:this.fb.array( this.police_answer,[Validators.required] ),
			published:false,
			mapData:['',Validators.required],
			place_location:['',Validators.required],
			news_link:'',
			news_link_2:'',
			image:'',
			perpetratorAge:'',
			perpetratorHeight:'',
			perpetratorWeight:'',
			perpetratorHairColor:'',
			perpetratorFeatures:'',
			acceptedToSend:'',
			recaptcha: ['', Validators.required],
			date:new Date()
		});

	}

	get f(){

		return this.freportAdd.controls;
	}


	isCategorySelected(event,category){
		var array=this.freportAdd.get('reportCat')as FormArray;
		if(event.target.checked){
			this.checkedCat=true;
			this.freportAdd.get('reportCat').setErrors(null);
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			this.checkedCat=false;
				array.controls.forEach(function (element,index) {
					if(element.value.name==category){
						array.at(index).patchValue({name:element.value.name,ischecked:false});
					}
				})	
		}
	}


	isPoliceAnswerSelected(event,answer){
		
		var arraypolice=this.freportAdd.get('policeAnswer')as FormArray;
		if(event.target.checked){
			this.checkedAnswer=true;
			this.freportAdd.get('policeAnswer').setErrors(null);
			arraypolice.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraypolice.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			this.checkedAnswer=false;
				arraypolice.controls.forEach(function (element,index) {
					if(element.value.name==answer){
						arraypolice.at(index).patchValue({name:element.value.name,ischecked:false});
					}
				})	
		}
	}

	getCoords(val:any) {
		this.mapDataValid=true;
		this.f.mapData.patchValue({lat:val.lat, lng:val.lng, label:this.freportAdd.get('title').value});
	} 

	uploadImage(event, name) {
		const file = event.target.files[0];
		const filePath = 'reportImages/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const task=this.uploadImageService.uploadImage(filePath, file);
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available
		let imageListener=task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					this.downloadURL=downloadURL;
					this.f.image.patchValue(this.downloadURL);

				});
			})
			).subscribe();
		imageListener.unsubscribe();

	}

	handleSuccessRecaptcha(response: any) {
		this.recaptcha=response;
	}

	acceptedToSend(acceptedToSend){
		var accepted=acceptedToSend.target.checked;
		this.f.acceptedToSend.patchValue(accepted);
	}

	sendMail(){
		this.emailService.sendSimpleCheckMail();
	}

	//save Report
	onSubmit({value}) {
		
		this.submitted = true;
		if (this.freportAdd.invalid || this.checkedCat==false||this.checkedAnswer==false) {
			return;
		}

		if(this.downloadURL)
		{
			this.f.image.patchValue(this.downloadURL);
		}	
		this.reportService.create(value).then((value) => {
			console.log('Created new item successfully!');
			this.sendMail();
			this.router.navigate(['add-report-success']);
		});
	}


	



}
