import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/shared/services/report.service';
declare const L: any; 
import { map } from 'rxjs/operators';
import { faList, faMapMarkedAlt, faFilter, faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-frontend-statistics',
	templateUrl: './frontend-statistics.component.html',
	styleUrls: ['./frontend-statistics.component.scss']
})
export class FrontendStatisticsComponent implements OnInit {
	allMarkers;
	markers;
	map;
	reports;
	markerIcon = L.icon({
		iconAnchor: [10, 41],
		popupAnchor: [2, -40],
		// specify the path here
		iconUrl: "../assets/img/mypin.png",
		shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
	});

	faList = faList;
	faMapMarkedAlt=faMapMarkedAlt;
	faFilter=faFilter;
	faClock=faClock;
	faMapMarkerAlt=faMapMarkerAlt;
	showList:boolean=true;
	showMap='hidden';
	allCat;
	disablePrevButton=true;
	disableNextButton;
	firstReport;
	lastReport;
	mainFirstReport;
	categories;
	objectReportLength=[];
	divStyle;
	colorAll;
	currentCat;

	constructor(private reportservice:ReportService) { }

	async ngOnInit() { 
		this.getAllReports();
		this.allMarkers = await this.reportservice.getMarkers();
		this.refresh();	
		this.allCat=await this.reportservice.filterAndCountReports();
		for(var rep of this.allCat){
			this.objectReportLength.push({name:rep.name,length:rep.count});
		}
		this.categories=this.objectReportLength;

		
	}

	private initMap(): void {
		this.map = new L.Map('mapList');
		// create the tile layer with correct attribution
		var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
		var osm = new L.TileLayer(osmUrl, {minZoom:8, maxZoom: 20, attribution: osmAttrib});  
		// set default coordinates
		this.map.setView(new L.latLng(41.662429, 21.759924),8);
		//add layer contributors
		this.map.addLayer(osm);
		//add markers on start
		this.createMarkers();
	}

	refresh(){
		this.initMap()
	}


	createMarkers(){
		this.markers = L.markerClusterGroup();
		for (var i = 0; i < this.allMarkers.length; i++) {
			var a = this.allMarkers[i]['map'];
			var title = a.label;
			var id=this.allMarkers[i]['id'];
			var marker = L.marker(new L.LatLng(a.lat, a.lng), { title: title, icon:this.markerIcon });
			marker.bindPopup('<a href="statistics/'+id+'">'+title+'</a>');
			this.markers.addLayer(marker);
		}
		this.map.addLayer(this.markers);
	}


	async filterMarkers(cat,i){
		this.currentCat=cat;
		this.allMarkers=await this.reportservice.filterReports(cat);
		//clear markers
		this.markers.clearLayers();
		//create markers based on category id
		this.createMarkers();
		this.reports=await this.reportservice.filterReportList(cat);	
		this.firstReport=this.reports[0].data;
		this.mainFirstReport=this.reports[0].data;
		this.lastReport=this.reports[this.reports.length-1].data;	
		this.divStyle=i;
		this.colorAll='';
		for (var m of this.categories){
			if(m.name==cat){
				if(m.length<11){
					this.disableNextButton=true;
				}else{
					this.disableNextButton=false;
				}
			}
		}
	}

	async getAllCat(){
		this.allMarkers=await this.reportservice.getMarkers();
		//clear markers
		this.markers.clearLayers();
		//create markers
		this.createMarkers();
		//call reports with all data
		this.getAllReports();
		this.colorAll='hover';
		this.divStyle='';
		
	}


	

 getAllReports(){
		
		let listener = this.reportservice.getAllPublichedReports().snapshotChanges().pipe(map(actions => actions.map(e => {
			return {id:e.payload.doc.id, data:e.payload.doc.data()}
		}))
		).subscribe(data => {	
			this.reports = data;
			this.currentCat='';
			this.firstReport=data[0].data;
			this.mainFirstReport=data[0].data;
			this.lastReport=data[data.length-1].data;
			listener.unsubscribe();
		});

	}


	nextReportPage(){
		let listener=this.reportservice.nextPublishedReport(this.lastReport,this.currentCat).snapshotChanges().pipe(
			map(actions => actions.map(a => {
				return {id:a.payload.doc.id, data:a.payload.doc.data()}
			}))
			).subscribe(data => {
				
				this.disableNextButton = true;
				var removeCount = 0;
				if (data.length == 11) {
					removeCount = 1;
					this.disableNextButton = false;
				}else{
					this.disablePrevButton=true;
				}
				var newArrayReport = [];
				for(let i=0;i<data.length-removeCount;i++){
					newArrayReport.push(data[i]);	
				}
				this.reports = newArrayReport;
				this.firstReport=newArrayReport[0].data;
				this.lastReport=newArrayReport[newArrayReport.length-1].data;
				this.disablePrevButton=false;
				listener.unsubscribe();
			});

		}

		prevReportPage(){
			let listener=this.reportservice.prevPublishedReport(this.firstReport,this.currentCat).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					return {id:a.payload.doc.id, data:a.payload.doc.data()}
				}))
				).subscribe(data => {
					this.reports = data;
					this.firstReport=data[0].data;
					this.lastReport=data[data.length-1].data;
					this.disableNextButton=false;
					if(this.mainFirstReport.title===this.firstReport.title){
						this.disablePrevButton=true;
					}
					listener.unsubscribe();
				});
			}




			showListReport(){
				this.showList=true;
				this.showMap='hidden';
			}

			showMapReport(){
				this.showList=false;
				this.showMap='visible';
				this.map.invalidateSize(true);
			}






		}
