import { Component, OnInit } from '@angular/core';
import Report from 'src/app/shared/models/report';
import Blog from 'src/app/shared/models/blog';
import { AngularFireStorage } from '@angular/fire/storage';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 

import { finalize } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BlogService } from 'src/app/shared/services/blog.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { UploadResponse } from '@kolkov/angular-editor';

@Component({
	selector: 'app-dashboard-blog',
	templateUrl: './dashboard-blog.component.html',
	styleUrls: ['./dashboard-blog.component.scss']
})
export class DashboardBlogComponent implements OnInit {
	blog:Blog=new Blog();
	submitted=false;
	uploadPercent;
	downloadURL;
	fblogAdd: FormGroup;

	

	constructor(private myStorage: AngularFireStorage,
		private uploadImageService:UploadImageService,
		private blogService:BlogService,
		public router:Router,
		private fb: FormBuilder,
		private http:HttpClient) { }

	ngOnInit(): void {
		this.createForm();
	}



	

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "15rem",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: true,
		customClasses: [
		
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
			class:"article_img"
		}],
	 // uploadUrl: '',
	 //    uploadWithCredentials: false,	

	};

	



	private createForm() {
		//inicialization
		this.fblogAdd = this.fb.group({
			title: ['', Validators.required],
			text: ['', Validators.required],
			// validates date format yyyy-mm-dd
			date: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
			image:''
		});
	}


	// convenience getter for easy access to form fields
	get f() { return this.fblogAdd.controls; }


	uploadImage(event, name) {
		const file = event.target.files[0];
		const filePath = 'blogImages/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const task=this.uploadImageService.uploadImage(filePath, file);
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available
		task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					this.downloadURL=downloadURL;
					console.log(this.downloadURL);
					this.f.image.patchValue(this.downloadURL);
				});
			})
			).subscribe();
		
	}




	onSubmit({value}) {
		this.submitted = true;
		if (this.fblogAdd.invalid) {
			return;
		}
		if(this.downloadURL)
		{
			this.f.image.patchValue(this.downloadURL);
		}
		this.blogService.create(value).then((value) => {
			this.router.navigate(['dashboard-blog-list']);
		});
	}


}
