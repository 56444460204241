import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import {  faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PublicationService } from 'src/app/shared/services/publication.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-dashboard-publication-list',
  templateUrl: './dashboard-publication-list.component.html',
  styleUrls: ['./dashboard-publication-list.component.scss']
})
export class DashboardPublicationListComponent implements OnInit {
	publicationP:any;
	faEdit=faEdit;
	faTrash=faTrash;

	constructor(private pubPosts:PublicationService, public router:Router) { }

	ngOnInit(): void {
		this.getAllPublicationPost();
	}

	addPublicationPost(){
		this.router.navigate(['dashboard-publication']);
	}

	getAllPublicationPost(){
		let publicationListener=this.pubPosts.getAll().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				this.publicationP = data;
				publicationListener.unsubscribe();
			});
		}


		deletePublicationPost(id, title){
			this.pubPosts.deleteData(id,title);
		}




}
