<app-dashboard-header></app-dashboard-header>
<div class="container page">
	<div class="row mt-4" >
	<div class="col-12" style="text-align: right;">
			<button type="button" class="btn btn-primary" (click)="addPublicationPost()">{{"Додади нова содржина"|translate}}</button>
		</div>
		<div class="col-12 title" style="padding-top: 0">{{"Листа на студии" | translate}}</div>
		
	</div>
	<div class="row list_grid list_grid_title mt-4">
		<div class="col-10" >{{"Листа на студии" | translate}}</div>
		<div class="col-1"><fa-icon [icon]="faEdit"></fa-icon></div>
		<div class="col-1"><fa-icon [icon]="faTrash"></fa-icon></div>
	</div>
	<div class="row list_grid"
	*ngFor="let pub of publicationP; let i = index" [class.active]="i == currentIndex">
	<div class="col-10 ">{{ pub.title }}</div>
	<div class="col-1"><a  routerLink="{{pub.id}}">{{"промени"| translate}}</a></div>
	<div class="col-1"><a (click)="deletePublicationPost(pub.id,pub.title)">{{"избриши"| translate}}</a></div>
</div>
</div>
<app-frontend-footer></app-frontend-footer>