// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
 firebase: {
  apiKey: "AIzaSyBs5t8eyjTdQDm2TV5n6aGB8Sm01a3iqBA",
  authDomain: "react-be-safe.firebaseapp.com",
  databaseURL: "https://react-be-safe-default-rtdb.firebaseio.com",
  projectId: "react-be-safe",
  storageBucket: "react-be-safe.appspot.com",
  messagingSenderId: "463956214424",
  appId: "1:463956214424:web:e0b104350c0cede0fac6ed",
  measurementId: "G-V5WXJWD76J"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
