import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ReportService } from 'src/app/shared/services/report.service';
import exporting from 'highcharts/modules/exporting';
import { TranslateService } from '@ngx-translate/core';

exporting(Highcharts);

@Component({
	selector: 'app-highcharts',
	templateUrl: './highcharts.component.html',
	styleUrls: ['./highcharts.component.scss']
})
export class HighchartsComponent implements OnInit {
	years;
	combineYears;
	combineSeries=[];
	isHighcharts = typeof Highcharts === 'object';
	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options;
	constructor(private reportservice:ReportService, public translate:TranslateService) { }

	async ngOnInit(){

		this.years=await this.reportservice.getYearChart();
		this.combineYears=[];
		let sortArr = this.years.sort();
		var current=null;
		var count=0;
		//console.time('Function #1');
		// for(var i=0;i<sortArr.length;i++){
			// 	if(sortArr[i]!=current){
				// 		if(count>0){
					// 			//this.combineSeries.push(count);
					// 			this.combineYears.push(current);
					// 		}
					// 		current=sortArr[i];
					// 		count=1;		
					// 	}else{
						// 		count++;
						// 		//this.combineSeries.push(count);
						// 			this.combineYears.push(current);
						// 	}
						// }
						// 	if(count>0){
							// 			this.combineSeries.push(count);
							// 			this.combineYears.push(current);
							// 		}

							for (var i = 0; i < sortArr.length; i++) {
								if (sortArr[i] != current) {
									if (count > 0) {
										this.combineSeries.push(count);
										this.combineYears.push(current);
									}
									current = sortArr[i];
									count = 1;
								} else {
									count++;
								}
							}
							if (count > 0) {
								this.combineSeries.push(count);
								this.combineYears.push(current);
							}
							//console.timeEnd('Function #1');

							this.Highcharts.setOptions({
								chart: {
									style: {
										fontFamily: 'Arsenal'
									}
								}
							});
							this.chartOptions = {
								title: {
									text: this.translate.instant("Поднесени пријави по година")
								},
								subtitle: {
									text: this.combineYears[0]+' - '+ this.combineYears[this.combineYears.length-1]
								},
								xAxis: {
									categories: this.combineYears,
								},
								yAxis: {
									title: {
										text: this.translate.instant("Број на поднесени пријави"),

									}
								},
								tooltip: {
									formatter: function() {
										return 'Година: '+'<b>'+this.x+'</b>'+'<br/>'+
										this.series.name+': '+'<b>'+this.y+'</b>'

									}
								},

								series: [{
									data: this.combineSeries,
									name:this.translate.instant("Број на поднесени пријави"),
									type: 'line',
									color:'#be365a'
								}],

								exporting: {
									buttons: {
										contextButton: {
											menuItems: [
											'viewFullscreen', 'separator', 'downloadPNG',
											'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
											]
										},
									},
									enabled: true,
								},
								navigation: {
									buttonOptions: {
										align: 'right',
										verticalAlign: 'top',
										y: 0
									}
								},

							}
						}

					}
