<app-frontend-header></app-frontend-header>
<div class="container page">
	<div class="row">

		<div class="col-md-12 col-xs-12">
			<div class="title">{{"Блог содржина"|translate}}</div>
		</div>
		<div class="col-md-4 col-xs-12" *ngFor="let blog of blogP">
			<div class="blog_box">
				<a routerLink="{{blog.id}}">
					<div class="blog_title">{{blog.title}}</div>
					
					<div *ngIf="blog.image" class="blog_image space" style="background-image: url('{{blog.image}}');"></div>
					<div class="blog_desc"><span [innerHTML]= "blog.text.slice(0,300) "></span><span class="read_more">{{"...Прочитајте повеќе..."| translate }}</span></div>
					<div class="blog_date"><fa-icon [icon]="faCalendarAlt"></fa-icon> {{blog.date | date:'dd-MM-yyyy'}}</div>
					
				</a>
			</div>
		</div>
	</div>
	<button *ngIf="disablePrevButton==false" class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevBlogPage()">{{"Претходна страница"|translate}}</button>
	<button *ngIf="disableNextButton==false" class="btn btn-primary btn-sm float-right" (click)="nextBlogPage()"  [disabled]="disableNextButton">{{"Следна страница"|translate}}</button>
</div>

<app-frontend-footer></app-frontend-footer>
